<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <base-card>
          <div slot="heading" class="title font-weight-light">
            {{ $t('users.new_annotation_manager') }}
          </div>
          <v-flex xs12 class="mt-5">
            <v-form ref="form" @submit.prevent="save">
              <p class="title">1. {{ $t('users.select_bpo') }}</p>
              <v-select
                :items="bpoOptions"
                label="BPO Organization"
                outline
                :rules="[rules.required]"
                @change="onBPOSelected"
              />
              <p class="title">2. {{ $t('users.enter_am_info') }}</p>
              <manager-form @input="onInputManager" />
              <div class="d-flex flex-row-reverse">
                <v-btn color="teal" dark class="mt-3" type="submit" :loading="isCreating">
                  {{ $t('common.save') }}
                </v-btn>
              </div>
            </v-form>
          </v-flex>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ManagerForm from '../components/ManagerForm.vue'
import BaseCard from '../components/common/BaseCard.vue'

export default {
  components: {
    ManagerForm,
    BaseCard,
  },
  data() {
    return {
      selectedBPO: null,
      user: null,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      isCreating: false,
    }
  },
  mounted() {
    this.fetchBPOs()
  },
  computed: {
    bpoOptions() {
      return this.bpos.map((bpo) => ({
        text: bpo.name,
        value: bpo.id,
      }))
    },
    ...mapState({
      bpos: (state) => state.bpo.bpos,
    }),
  },
  methods: {
    ...mapActions(['createAnnotationManager', 'fetchBPOs']),
    save() {
      const valid = this.$refs.form.validate()
      if (!valid) {
        return
      }
      const payload = {
        organizationID: this.selectedBPO,
        email: this.user.email,
        role: this.user.role,
      }
      this.isCreating = true
      this.createAnnotationManager(payload)
        .then(() => {
          this.isCreating = false
          this.$snotify.success(this.$t('common.create_successfully'))
          this.$router.push({ name: 'users' })
        })
        .catch(() => {
          this.isCreating = false
        })
    },
    onBPOSelected(orgId) {
      this.selectedBPO = orgId
    },
    onInputManager(user) {
      this.user = {
        ...user,
        role: 3, // AnnotationManager role
      }
    },
  },
}
</script>
