<template>
  <div class="mt-3">
    <v-text-field
      v-model="email"
      type="email"
      label="Email"
      required
      :rules="emailRules"
      @input="input"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  methods: {
    input() {
      const data = {
        email: this.email,
      }
      this.$emit('input', data)
    },
  },
}
</script>

<style>
.card {
  padding: 32px;
}
</style>
